// menu
$menu-width: 10vw;
$menu-height: 100vh;

// header
$header-height: 2.5vw;
$header-width: calc(100vw - $menu-width);
$header-uploadtable-fontweight: 400;

// worklist
// $worklist-width: 2vw;



// fonts
// $font-size-min: 6px;
$font-size: 0.7vw;
$font-size-menu: 0.8vw;

// theme color
$home-left-background: #202223;
$dark-default-background: #292d30;
$dark-layer0-background: #313438;
$dark-layer1-background: #383c41;
$dark-layer2-background: #4d5055;
$dark-logo-fill: #ffffff;
$dark-default-font: #ffffff;
$dark-active-font: #118af7;
$dark-active-negative-font: #f71111;
$dark-inactive-font: #91919c;
$dark-hover: #5e6166;

$report-title: #5a7ab5