@import "3_styles/_variables";
@import "3_styles/_mixins";

:export {
  pressedColor: $dark-default-background;
}

.inputRange{
  width:40%;
  > div{
    width:90%;
    // border:1px red solid;
  }
}
.container {
  // @include borderBox(4px, rgb(76, 196, 46));
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  @include flexTo(column, flex-start, stretch);
  // overflow:scroll;
}

.viewController {
  // @include borderBox(2px, blue);
  background:#34383C;
  height: 2.5vw;
  @include flexTo(row, center, stretch);

  >div {
    // @include borderBox(4px, $dark-default-background);
    border-left:2px $dark-default-background solid;
    border-right:2px $dark-default-background solid;
    // gap:2vw;
    @include flexTo(row, center, center);

    &.mip {
      width: 20%;
      border-left: none;
      display: flex;
      gap: 0.5vw;
      // justify-content: flex-end;
    }

    &.overlay {
      width: 25%;
      display: flex;
      gap: 0.5vw;
    }

    &.intensity {
      width: 20%;
      display: flex;
      gap: 0.5vw;
    }

    &.btnGroup {
      width: 10%;
      display: flex;
      gap: 1vw;
    }

    &.colormap {
      width: 15%;
    }

    &.burger {
      width: 5%;
      border-right: none;
    }

    >span {
      // @include borderBox(1px, yellow);
      // width:1.7vw;
      height:1.7vw;
      @include flexTo(row, center, center);

      &.reactIcon{

        margin: 0px 2px;
        cursor:pointer;
        @include borderBox(1px, transparent);
        // background: yellow;
        filter: drop-shadow(4px 4px 4px #000);
        // &:hover{
        //   @include borderBox(1px, $dark-active-font);
        // }
        &:active{
          background:$dark-default-background;
        }
      }

      >svg {
        // border: 1px red solid;
        // height:100%;
      }

      // display:flex;
      // align-items:center;
      // width:
    }
  }
}

.divWrapper {
  // @include borderBox(3px, blue);
  width: 100%;
  // height:100%;

  // height:100%;
  // overflow:scroll;
  >div {
    // @include borderBox(3px, red);
    @include flexTo(row, center, flex-start);

    &.divUpper {
      >div {
        // @include borderBox(1px, white);
        overflow: hidden;
        // width:30%;
        height: 100%;

        &.divCoronal {
          // border: 1px red solid;
          // @include flexTo(row, flex-end, flex-end);
        }

        &.divSagittal {
          // @include flexTo(row, flex-start, flex-end);
        }
      }
    }

    &.divLower {
      >div {
        // @include borderBox(1px, white);
        overflow: hidden;
        // width:30%;
        height: 100%;

        // height:100%;
        // @include flexTo(row,center,center);
        &.divAxial {
          // @include flexTo(row, flex-end, flex-start);
          // display
        }

        &.divMip {
          // @include flexTo(row, flex-start, flex-start);
        }
      }
    }

    // >.divCoronal, .divSagittal, .divAxial, .divMip{
    //   border:1px white solid;
    //   overflow:hidden;
    //   height:20%;
    //   width:40%;
    // }
  }
}