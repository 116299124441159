@import "3_styles/variables";
@import "3_styles/_mixins";

.container {
  background: $dark-layer1-background;
  // border: 1px red solid;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 10vw;

  // @include animation(0s, 0.7s, 1, forwards, slidein_left);
  &.hidden {
    // left: calc(20px - $menu-width);
    left: -$menu-width;
  }

  transition: all 0.5s ease;
}

@include keyframe(slidein_left) {
  0% {
    left: -$menu-width;
  }

  100% {
    left: 0;
    // left: -$menu-width;
  }
}

.logo-container {
  // border: 1px solid blue;
  box-sizing: border-box;
  display: flex;
  height: $header-height;
  display: flex;
  align-items: center;
  justify-content: center;
  // cursor:pointer;
}

.logout-container {
  border: 1px solid green;
  box-sizing: border-box;
  display: flex;
  height: 3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vw 0.5vw 0.5vw 0.5vw;
}

.logo-style {
  // border: 1px solid yellow;
  padding: 1vw 1vw 0 1vw;
  // box-sizing: border-box;
  // height: 1.5vw;
  width: 100%;
  height: 100%;

  
}

.license-info {
  // border: 1px solid cyan;
  background-color: $dark-layer2-background;
  box-sizing: border-box;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // justify-content: space-evenly;
  color: white;
  // margin-bottom: 1vw;
  padding: 0 0.5vw 0 0.5vw;

  >div {
    // border: 1px solid blue;
    display: flex;

    padding: 0.5vw 0.5vw 0.5vw 0.1vw;
    justify-content: start;
    align-items: center;
    font-size: $font-size;

    >span {
      // border:1px solid red;
      height: 1.2vw;
      box-sizing: border-box;
      margin-left: 0.7vw;
      margin-top: 0.2vw;
      font-size: $font-size;

    }
  }
}