@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  position: relative;
  top: 0;
  left: 0;
  // outline: 5px red solid;
  // border: 4px red solid;
  // box-sizing: border-box;
  background: $home-left-background;
  color: white;
  height: 100vh;
  .leftfloat {
    @include flexTo(column, center, center);
    // @include borderBox(1px, red);
    float: left;
    width: 50vw;
    height: 100vh;
    gap: 2vw;
   
    > div:nth-child(2) {
      @include borderBox(1.5px, white);
      @include flexTo(column, center, center);
      width: 10vw;
      height: 2vw;
      font-size: 1.1vw;
      border-radius: 3px;
      cursor: pointer;
      &:hover {
        background-color: $dark-active-font;
      }
    }
  }
  .rightfloat {
    float: right;
    width: 50vw;
    height: 100vh;
    position: relative;
    @include animation(0s, 0.7s, 1, forwards, slidein_right);
  }
}
@include keyframe(slidein_right) {
  0% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
