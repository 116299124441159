@import "3_styles/_variables";
@import "3_styles/_mixins";

.btnContainer {
  margin: 1vw 2vw;
  // border: 1px solid red;
  display: flex;
}

.btnGroup1 {
  // border: 1px solid white;
  display: flex;
  gap: 0.5vw;
  align-items: center;
  margin-left: auto;
}