@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  // @include borderBox(4px, red);
  // margin-top: 0.8vw;
  // border: 1px red solid;
  // box-sizing: border-box;
  // height:100%;
  max-height: 100%;
  // min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space: nowrap;
  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    // width:50%;
    background-color: grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: $dark-layer2-background;
  }
  position:relative;
}
