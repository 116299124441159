@import "3_styles/_variables";
@import "3_styles/_mixins";

%input__common {
  // border: 1px solid yellow;
  padding-top: 1vw;
  .input-title {
    font-size: 0.7vw;
  }
  .input-content {
    > label {
      display: flex;
      flex-direction: column;
      > input {
        font-size: 0.7vw;
        line-height: 2vw;
        margin-top: 0.3vw;
        padding-left: 1.5vw;
        background-color: #202223;
        color: white;
        border: 0;
        outline: none;
      }
    }
  }
}

.signin-input {
  // border: 1px solid yellow;
  @extend %input__common;
  .input-title {
    font-size: 1vw;
    // color: red;
  }
  .input-content {
    > label {
      > input {
        font-size: 0.9vw;
        line-height: 3vw;
      }
    }
  }
}

.signup-input {
  // border: 1px solid yellow;
  @extend %input__common;
}
