@import "3_styles/_variables";
@import "3_styles/_mixins";
@import "../ReportPage1/ReportPage1.module.scss";

$table-width: 80%;

%Page3Title {
  align-self: stretch;
  @include flexTo(row, flex-start, center);
  color: $report-title;
  font-size: 20px;
  margin: 10px 0;
  font-weight: bold;

  >img {
    width: 24px;
  }
}

.ImageContainer{
  // border: 3px red solid;
  margin-top: 25px;
  @include flexTo(column, flex-start, stretch);
  height: 100%;

  // @include flexTo(column, center, stretch);
  >.ReportTitle {
    @extend %Page3Title;
  }
  >.imageWrapper {
    // @include borderBox(3px, green);
    @include flexTo(column, flex-start, center);
    // width: $table-width;

    // height:100%;
    // overflow:hidden;
    >.imageTitle {
      // @include borderBox(1px, blue);
      // padding: 0 10%;
      margin: 20px 0 10px 100px;
      width: 100%;
      font-weight: 500;
      font-size: 18px;
    }
  }
}

.SUVRImage{
  // border:1px solid red;
  height: 300px;
  box-sizing: border-box;
  display: flex;

  
}

.SUVRImage_box{
  width: 90%;
  >div{
    // border:1px solid green;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50%;
    >div{
      // border:1px solid blue;
      box-sizing: border-box;
      width: 25%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      >img{
        // border:1px solid red;
      }
    }
  }
}

.surfaceImgWrapper{
  // border:1px solid blue;
  width: 100%;
  display: flex;
  position:relative;
  >div{
    display: flex;
    // border:1px solid rebeccapurple;
    width: 100%;
    justify-content: center;
    
  }
  // >div{
  //   border: 1px solid orangered;
  //   display: flex;
  //   flex-direction: column;
  //   >div{
  //     display: flex;
  //     >div{
  //       border:1px solid purple;
  //     }
  //   }
  // }
}

.surfaceImg{
  width: 170px;
}

.colorbarHot{
  // border:1px solid blue;
  width: 150px;
  position:absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  >div:nth-child(1){
    width: 100%;
    // border:1px solid red;
    display: flex;
    justify-content: flex-end;
    // justify-content: center;
    >img{
      width: 15px;
      height: 250px;
      border-radius: 10px;
    }
    >div{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      >div{
        // border:1px solid red;
        font-size: 12px;
        display: flex;
        align-items: center;
        width: 65px;
        // >img{
        //   margin-right: 5px;
        // }
      }
    }
  }

  >div:nth-child(2){
    // border:1px solid green;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
  }
}

.colorbarJet{
  // border:1px solid blue;
  width: 150px;
  position:absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 32%;
  
  >div:nth-child(1){
    width: 100%;
    // border:1px solid red;
    display: flex;
    justify-content: flex-end;
    // justify-content: center;
    >img{
      width: 15px;
      height: 250px;
      border-radius: 10px;
    }
    >div{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      >div{
        // border:1px solid red;
        font-size: 12px;
        display: flex;
        align-items: center;
        width: 65px;
        // >img{
        //   margin-right: 5px;
        // }
      }
    }
  }

  >div:nth-child(2){
    // border:1px solid green;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: center;
  }
}