@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  // border: 5px yellow solid;
  position: relative;
  >.reportContainer{
    // @include borderBox(3px, red);
    position:absolute;
    transform:translate(-50%,0);
    top:0;
    left:50%;
    // z-index:-1;
  }
}

.buttons {
  // border: 1px yellow solid;
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index:0;
  top: 4rem;
  right: 3rem;
  > div {
    display:flex;
    align-items:center;
    margin: 0.2vw;
    padding: 0.8vw;
    background: gray;
    user-select: none;
    cursor: pointer;
    font-size:0.8vw;
    font-weight:600;
    @include borderBox(1px, white);
    &:hover{
      @include borderBox(1px, $dark-active-font);
    }
    &:active{
      background:$dark-layer1-background;
    }
  }
}
