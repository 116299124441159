@import "3_styles/_variables";
@import "3_styles/_mixins";

:export {
  spinner-color: $dark-active-font;
}

.container {
  @include flexTo(row, center, center);
  // @include borderBox(1px, red);
  background: rgba(0,0,0,0.5);
  color: white;

  position: fixed;
  top:0;
  bottom: 0;
  left:0;
  right:0;

  z-index:2;
  > .exitButton {
    @include exitButton();
  }
  >.spinnerImg{
    // border:1px red solid;
    // width: 30vmin;
    >svg{
      width:100%; 
      animation: spinLoader 3500ms steps(300, end) infinite;
    }
  }
  >.msgText{
    // border:1px red solid;
    font-size: 4vmin;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%)
  }
}
@keyframes spinLoader {
  from { transform: rotate(0turn);}
  to { transform: rotate(1turn);}
}
