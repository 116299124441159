@import "3_styles/_variables";
@import "3_styles/_mixins";

.container{
  @include flexTo(row, center, center);
  // @include borderBox(1px, red);
  background: rgba(0,0,0,0.5);
  color: white;

  position: fixed;
  top:0;
  bottom: 0;
  left:0;
  right:0;

  z-index:2;
  
}

.licenseBox{
  // border:1px solid red;
  width: 30%;
  height: 40%;
  background-color: #383C41;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  >div:nth-child(1){
    // border:1px solid red;
    width: 1vw;
    height: 15%;
    >img{
      width: 100%;
    }
  }
  >div:nth-child(2){
    // border:1px solid red;
    font-size: 1.1vw;
    font-weight: bold;
    height: 15%;
  }
  >div:nth-child(3){
    // border:1px solid red;
    font-size: 1.1vw;
    font-weight: bold;
    height: 15%;
  }

  >div:nth-child(4){
    font-weight: 300;
    // border:1px solid red;
    height: 10%;
  }
  >div:nth-child(5){
    font-weight: 300;
    // border:1px solid red;
    height: 20%;
  }
  >div:nth-child(6){
    // border:1px solid red;
    font-size: 0.8vw;
    font-weight: 500;
  }
}