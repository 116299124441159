@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  @include borderBox(4px, blue);
  @include flexTo(row, center, stretch);
  margin:0 0 20px 0;

  .viewerContainer {
    width: 90%;
    @include flexTo(row, center, center);
    flex-wrap: wrap;
  }

  .cmapContainer {
    @include flexTo(column, center, center);
    width: 10%;
    >.colorbar{
      // border:4px blue solid;
      border-radius: 50px;
      width:20%;
      height:80%;
      min-height: 8vw;
    }
  }
}