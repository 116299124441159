@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  width: 95%;
  margin: 1vw 2vw;
  border: 4px solid red;
  display: flex;
  // justify-content: space-between;
}

.pInfo {
  display: flex;
  width: 50%;
  justify-content: stretch;
  border: 1px solid yellow;

  > div {
    // border: 1px solid white;
    flex-basis: 100%;
    overflow: hidden;
    // border: 1px solid yellow;
    > div:nth-child(1) {
      font-weight: 230;
      text-align: center;
      font-size: 0.8vw;
      white-space: nowrap;
      overflow: auto;
    }
    > div:nth-child(2) {
      padding: 0 0.2vw;
      text-align: center;
      font-weight: 400;
      margin-top: 0.5vw;
      white-space: nowrap;
      overflow: scroll;
      @include scrollInvisible();
    }
  }
}

.indicator {
  border: 1px solid yellow;
  width: 40%;
  // color: red;
  display: flex;
  flex-direction: column;
  > div {
    // padding-left: 2.8vw;
    // border: 1px white solid;
    display: flex;
    height: 100%;
    > div:nth-child(1) {
      font-weight: 230;
      font-size: 0.8vw;
      width: 5vw;
    }
    > div:nth-child(2) {
      font-weight: 400;
    }
  }
}

.burger {
  width: 10%;
  border: 1px solid yellow;
  display: flex;
  justify-content: end;
  align-items: center;
}
