@import "3_styles/_variables";
@import "3_styles/_mixins";

.container_wrapper {
  position: relative;
  padding: 1vw 1.5vw 0vw 1.5vw;
  @include flexTo(column, center, center);
  // align-self: flex-start;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  max-height: 80vw;
  min-height: 40vw;
  // border: 1px yellowGreen solid;
  box-sizing: border-box;
}

.container {
  // border: 4px orange solid;
  width: 99%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $dark-layer1-background;
  padding: 4.5%;
  margin-bottom: 2%;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s ease;
}

.top {
  // border:1px solid red;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5vw;

  >div:nth-child(1) {
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
  }

  >div:nth-child(2) {
    margin-left: 0.5vw;
    font-size: calc(6px + 0.5vw);
  }
}

.bottom {
  // @include borderBox(4px, blue);
  @include flexTo(row, flex-start, start);
  width: 100%;
  height: 100%;
  // margin:auto;
  // display: flex;
  // flex-direction:row;
  // justify-content: flex-start;
  // align-items:flex-start;infoWrapper
  overflow-y: auto;
  overflow-x: hidden;
}

.infoWrapper {
  // border:1px solid red;
  position: relative;

  >div {
    display: flex;
    align-items: center;
    justify-content: center;

    >span {
      font-size: 1vw;
      font-weight: 700;
    }

    >img {
      width: 1.3vw;
    }
  }
}

.centiloidInfo {
  // border:1px solid yellow;
  background-color: $dark-layer2-background;
  position: absolute;
  top: 50%;
  left: 100%;
  font-size: 0.5vw;
  white-space: normal;
  width: 10vw;
  height: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
}