@import "3_styles/_variables";
@import "3_styles/_mixins";

:export {
  spinnerColor: $dark-active-font;
}

.loading{
  // @include borderBox(5px, blue);
  @include flexTo(row, center, center);
  width:100%;
  height:100%;
  position: absolute;
  top: 0;
  left: 0;
  >.loadIcon{
    width:30%;
    // @include borderBox(3px, red);
    user-select:none;
    animation: spinLoader 1500ms steps(300, end) infinite;
    @include flexTo(row, center, center);
    position:relative;
  }
  >.loadMsg{
    // @include borderBox(3px, blue);
    font-size:2vw;
    position:absolute;
    transform: translate(-50%, -50%);
    top:50%;
    left:50%;
  }
}
@keyframes spinLoader {
  from { transform: rotate(0turn);}
  to { transform: rotate(1turn);}
}
