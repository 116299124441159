@import "3_styles/_variables";
@import "3_styles/_mixins";

* {
  font-family: "Montserrat", sans-serif;
  // font-size: calc(6px + 0.5vw);
  font-size: 0.7vw;
  font-weight:400;

  
  
  &::-webkit-scrollbar {
    width:0.5vmax;
    height: 0.5vmax;
    max-width: 10px;
    max-height:10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    // width:50%;
    background-color: grey;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: $dark-layer2-background;
  }
}
// * {
//   margin: 0;
//   padding: 0;
//   font: inherit;
//   color: inherit;
// }
// *,
// :after,
// :before {
//   box-sizing: border-box;
//   flex-shrink: 0;
// }
// :root {
//   -webkit-tap-highlight-color: transparent;
//   -webkit-text-size-adjust: 100%;
//   text-size-adjust: 100%;
//   cursor: default;
//   line-height: 1.5;
//   overflow-wrap: break-word;
//   -moz-tab-size: 4;
//   tab-size: 4;
// }
// html,
// body {
//   // height: 100%;
//   background:$dark-layer0-background;
// }
// img,
// picture,
// video,
// canvas,
// svg {
//   display: block;
//   max-width: 100%;
// }
// button {
//   background: none;
//   border: 0;
//   cursor: pointer;
// }
// a {
//   text-decoration: none;
// }
// table {
//   border-collapse: collapse;
//   border-spacing: 0;
// }

.App {
  // input:-webkit-autofill,
  // input:-webkit-autofill:hover,
  // input:-webkit-autofill:focus,
  // input:-webkit-autofill:active {
  //   /* background-color: red !important; */
  //   -webkit-text-fill-color: #fff !important;
  //   -webkit-box-shadow: inset 0 0 0px 9999px #202223;
  // }
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;

  all:none;
}
