@import "3_styles/_variables";
@import "3_styles/_mixins";

.table {
  // border: 4px white solid;
  margin-top: 0.8vw;
  width: 100%;
  height: 95%;
  // min-height: 90%;
  text-align: center;
  border-collapse: collapse;
  // border-collapse: separate;
  // border-spacing: 10px;
  table-layout: fixed;

  td {
    white-space: nowrap;

    // @include borderBox(1px, red);
    &.svgImage {
      border: 1px $dark-default-background solid;
      width: 23%;
      // @include flexTo(row, center, center);
      >div {
        // background:gray;
        @include flexTo(row, center, center);
      }
    }

    &.colorBar {
      border: 1px $dark-default-background solid;
      width: 77%;

      >div {
        @include flexTo(row, flex-end, stretch);
        box-sizing: border-box;
      }
    }
  }

  .tbody {
    >tr { 
      >td {
        // border:1px solid yellow;
        border-left:none;
        border-right: none;
      }
      
        // border:1px solid yellow;
        border-collapse: separate;
        border-spacing: 0, 0.3vw;
        >td{
          >.labels {
            display: flex;
            justify-content: space-between;
          }
        }
        // color:red;
        .colorbar{
          width: 100%;
          height: 0.7vw;
          border-radius: 10px;
        }
        >.amylGradient_bar {
          width: 100%;
          height: 0.7vw;
          border-radius: 10px;
          // #FFFEFD,
          // #ffdebe85,  
          // background: linear-gradient(to right,
          //     #ffebda,  
          //     #FFDFBE,
          //     #F9C697,
          //     #F7AB7E,
          //     #FF8075,
          //     #F26162,
          //     #E14349,
          //     #CF282B,
          //     #850303,
          //   );
        }
  
        >.datGradient_bar {
          width: 100%;
          height: 0.7vw;
          border-radius: 10px;
          // #bcf9ff,
          // background: linear-gradient(to right,
          //     #2400d8,
          //     #181cf7,
          //     #2857ff,
          //     #3d87ff,
          //     #56b0ff,
          //     #75d3ff,
          //     #99eaff,
          //     #bcf9ff,
          //     #ddfcff
          // );
        }
  
        >.fdgGraident_bar{
          width: 100%;
          height: 0.7vw;
          border-radius: 10px;
          // background: linear-gradient(to right,
          //     #000093,
          //     #0007ff,
          //     #005bff,
          //     #00a7ff,
          //     #00eeff,
          //     #2fffcf,
          //     #53ffab,
          //     #83ff7b,
          //     #cbff33,
          //     #fcff02,
          //     #ff8a00,
          //     #ff1300,
          //     #970000
          // );
        }
    }
  }
    .divForPadding{
      // border:1px solid red;
      height: 2.5vw;
      display: flex;
      // justify-content: center;
      align-items: center;
    }

  // .trForPadding{
  //   border-top:1px solid $dark-default-background;
  //   border-bottom: 30px solid #383c41;
  // }
}
