@import "3_styles/_variables";
@import "3_styles/_mixins";


.container{
  // border:1px solid red;
  background: $dark-default-background;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap:2vw;
  align-items: center;
  justify-content: center;
}

.goDashboard{
  border:1px solid red;
	box-sizing: border-box;
  width: 30%;
  padding:1vw;
  text-align: center;
}

.boxContainer{
  // border:1px solid yellow;
	box-sizing: border-box;
	height: 50%;
	flex-wrap: wrap;
	width: 100%;
	display: flex;
	justify-content: center;
	// align-items: center;
	// flex-direction: column;
}

.box{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// border:1px solid red;
	box-sizing: border-box;
	flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 35%;
	height: 50%;
}

.boxContents{
	padding: 2%;
	width: 80%;
	height: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.8vw;
	border-radius: 15px;
	>div:nth-child(1){
		font-size: 1vw;
	}
	>div:nth-child(2){
		color: #91919C;
	}

}

.licenseInfo{
	// border:1px solid red;
	font-size: 1vw;
	white-space: normal;
	text-align: center;
}