@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  // border:1px solid red;
  max-height: 100%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: $dark-layer2-background;
  }

  position:relative;
}