@import "3_styles/_variables";
@import "3_styles/_mixins";
@import "5_components/2_Modal/UploadingModal.module.scss";

.boxContainer{
  // border:1px solid red;
  transition:all 0.3s ease;
  position:relative;
  min-width: 100%;
  height:100%;
  padding: 2% 4% 4% 4%;
  box-sizing: border-box;
  background: $dark-layer1-background;
  @include flexTo(column, flex-start, flex-start);
  >.backButton{
    position:absolute;
    top:0;
    left:0;
    font-size: 1.2vw;
    margin:0.5vw 1vw;
    font-weight: 600;
    user-select:none;
    cursor:pointer;
    &:hover{
      color: $dark-active-font;
    }
  }
  > .boxTitle {
    padding-top:2vw;
    // border: 1px red solid;
    font-size: 1.8vw;
    margin:auto;
    // margin-bottom: 15px;
  }
  > .boxContent{
    // @include borderBox(1px, yellow);
    @include flexTo(row, center, center);
    align-content: center;
    flex-wrap: wrap;
    height: 100%;
    // min-height:30vw;
    width:100%;
    overflow-y: scroll;
    > span{
      width: 16vw;
      // min-width:300px;
      height: 8vw;
      margin: 2%;
      border-radius: 10px;
      cursor: pointer;
      user-select:none;
      background: $dark-layer2-background;
      @include flexTo(column, center, center);
      > div{
        font-size: 1.2vw;
      }
      &.active{
        background: $dark-default-background;
      }
    }
  }
  >.buttonGroup{
    width:100%;
    @include flexTo(row, center, center);
    // border:5px red solid;
    > .nextButton{
      @include nextButton();
    }
  }
}
