@import "3_styles/_variables";
@import "3_styles/_mixins";
@import "../ReportPage1/ReportPage1.module.scss";

$table-width: 80%;

%Page2Title {
  align-self: stretch;
  @include flexTo(row, flex-start, center);
  color: $report-title;
  font-size: 20px;
  margin: 10px 0;
  font-weight: bold;

  >img {
    width: 30px;
  }
}


.QuantContainer {
  // border: 3px red solid;
  margin-top: 25px;
  @include flexTo(column, flex-start, center);
  height: 100%;

  // @include flexTo(column, center, stretch);
  >.ReportTitle {
    @extend %Page2Title;
  }

  >.d3Graph {
    // @include borderBox(3px, green);
    @include flexTo(row, center, flex-start);
    // width: $table-width;
    width: 100%;
    height: 25%;

    .bar {
      // fill: darkslateblue;
      fill: red;
      // border:1px black solid;
    }

    // .bar-label{
    //   text-anchor: end;
    // }
    // .axis-label {
    //   text-anchor: middle;
    //   font-size: 13px;
    // }
    .axis line {
      fill: none;
      stroke: black;
    }

    .axis path {
      display: none;
    }

    .axis text {
      font-size: 15px;
    }

    .axis {
      border: 1px red solid;
      // color:red;
    }

  }

  >.typeTableWrapper {
    // @include borderBox(3px, green);
    width: $table-width;

    // height:100%;
    // overflow:hidden;
    // @include borderBox(1px, blue);
    >.tableTitle {
      // @include borderBox(1px, blue);
      margin: 20px 0 10px 0;
      width: 100%;
      font-weight: 600;
      font-size: 17px;
    }

    >.tableContainer {
      >table {

        // @include borderBox(3px, blue);
        // height:100%;
        thead {
          height: 35px !important;

          th {
            // @include flexTo(row,center,center);
            background-color: rgba(202, 202, 202, 1);

            >div {
              >span {
                font-size: 16px !important;
                font-weight: 600 !important;
              }
            }
          }
        }

        tbody {
          tr {
            height: 25px !important;
          }
        }

        th,
        td {
          font-size: 15px;
          padding-left: 0 !important;

          &:nth-child(1) {
            text-align: center;
            // @include borderBox(1px, red);
            width: 55%
          }

          &:nth-child(n+2) {
            width: 15%;
          }
        }
      }
    }
  }
}