@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  width: 97.5%;
  margin: 1vw 0 1vw 0;
  // border: 1px solid red;
  display: flex;
  height: 3vw;
  display: flex;
  justify-content: end;
  // gap:2vw;
}

%pInfo {
  display: flex;
  width: 37%;
  justify-content: stretch;
  // border: 1px solid yellow;
  // gap:0.5vw;
  > div {
    // border: 1px solid white;
    width: 15%;
    // flex-basis: 100%;
    overflow: hidden;
    // border: 1px solid yellow;
    
    > div:nth-child(1) {
      font-weight: 230;
      text-align: center;
      font-size: 0.8vw;
      white-space: nowrap;
      overflow: hidden;
      font-size: 0.7vw;
    }
    > div:nth-child(2) {
      padding: 0 0.2vw;
      text-align: center;
      font-weight: 400;
      margin-top: 0.5vw;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      // font-size: 0.7vw;
      // @include scrollInvisible();
      // border:1px red solid;
      height: 100%;
      font-size: 0.7vw;
      // @include flexTo(row, center, center);
      &.Tracer{
        // @include scrollInvisible();
        overflow: hidden;
        // color:green;
        >span{
          border-left: 6px solid;
          height: 1.1vw;
          padding: 0 10px;
          display: inline-block;
          // border:1px red solid;
          // box-sizing:border-box;
          // color:red;
        }
      }
      &.Patient_name{
        // overflow-x: scroll;
        // border:1px red solid;
        // >span{
        //   border:1px red solid;
        // }
      }
    }
  }
}

.pInfo {
  @extend %pInfo;
  >.Tracer{
  }
  >.Patient_name{
    width:30%;
    >.Patient_name{
      // border:1px red solid;
      padding-top:0.5px;
      overflow:visible;
      &:hover{
        white-space:nowrap;
        animation-name:marquee;
        animation-timing-function:linear;
        animation-duration:5s;
        animation-iteration-count:infinite;
      }
    }

  }
  >.Patient_ID{
    width:20%;
  }
}
@keyframes marquee {
  from   { transform: translate(0%);}
  99%,to { transform: translate(-100%);}
}

.indicator {
  // border: 1px solid yellow;
  width: 36%;
  // color: red;
  display: flex;
  flex-direction: column;
  > div {
    // padding-left: 2.8vw;
    // border: 1px white solid;
    display: flex;
    height: 100%;
    > div:nth-child(1) {
      font-weight: 230;
      font-size: 0.7vw;
      width: 5vw;
    }
    > div:nth-child(2) {
      font-weight: 400;
      font-size: 0.7vw;
    }
  }
}

.burger {
  width: 10%;
  // border: 1px solid yellow;
  display: flex;
  justify-content: end;
  align-items: center;
}
