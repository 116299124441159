@import "3_styles/_variables";
@import "3_styles/_mixins";

.ImageViewer1 {
  // border:5px white solid;
  // box-sizing:border-box;
  color: red;
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:0;
  &.hidden {
    visibility: hidden;
  }
  &.visible {
    visibility: visible;
  }
  .ImageViewer1-container{
    // border:1px blue solid;
    // box-sizing:border-box;
    position:relative;
    width:100%;
    height:100%;
    > .viewportElement1-container {
      overflow:hidden;
      // border:1px red solid;
      // box-sizing:border-box;
      &.coronal {
        position: absolute;
        top:0%;
        right:50%;

        height: 45%;
        width:36.45%;
        @include flex-center-center();
        > .viewportElement1 {
          width: 119%;
        }
      }
      &.sagittal {
        position: absolute;
        top:0%;
        left:50%;

        height: 45%;
        width:36.45%;
        @include flex-center-center();
        flex-shrink: 0;
        > .viewportElement1 {
          width:117%;
        }
      }
      &.axial {
        position: absolute;
        top:45%;
        right:50%;

        height: 55%;
        width: 36.45%;
        @include flex-center-center();
        > .viewportElement1 {
          height: 117%;
          width: 119%;
        }
      }
      &.mip {
        position: absolute;
        top:45%;
        left:50%;

        height: 55%;
        width: 36.45%;
        @include flex-center-center();
        > .viewportElement1 {
          width: 155%;
        }
      }
      &.coronal,
      &.sagittal,
      &.axial,
      &.mip {
        > div {
          position: absolute;
          user-select: none;
          &.patient-name {
            top: 0;
            left: 0;
          }
          &.image-number {
            bottom: 0;
            left: 0;
          }
          &.direction-indication {
            top: 50%;
            width: 100%;
            height: 0;
            // border: 1px red solid;
            // box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            > span {
              margin: 0 5%;
            }
          }
          &.direction-indication1 {
            left: 50%;
            height: 90%;
            width: 0;
            // border: 1px red solid;
            // box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > span {
              // margin: 10% 0;
            }
          }
          &.direction-indication2 {
            left: 50%;
            height: 100%;
            width: 0;
            // border: 1px red solid;
            // box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            > span {
              // margin: 10% 0;
            }
          }
        }
      }
      > .viewportElement1 {
      //   position: absolute;
      //   // top: 50%;
      //   // left: 50%;
      //   // transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
      }
    }
  }
}
.ImageViewer2 {
  color: red;
  width: 100%;
  height: 100%;
  @include flex-center-center();
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  &.hidden {
    visibility: hidden;
  }
  &.visible {
    visibility: visible;
  }
  align-items: flex-start;
  justify-content: flex-start;
  > .viewportElement2 {
    border: 5px purple solid;
    position: relative;
    // width: 25%;
    // height: 33.3%;
    > div {
      position: absolute;
      &.image-number {
        bottom: 0;
      }
      &.direction-indication {
        top: 50%;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        > span {
          margin: 0 10%;
        }
      }
      &.direction-indication2 {
        left: 50%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > span {
          margin: 10% 0;
        }
      }
    }
  }
}

.viewportElement2 {
  // position:absolute;
  // top:50%;
  // left:50%;
  // display:none;
  // border: red 3px solid;
  // box-sizing:border-box;
  // border: blue 1px solid;
  // box-sizing:border-box;
  // position: relative;
  // width: 22%;
  // height:25%;
  // height: 100px;
  // display:flex;
  // justify-content:center;
  // align-items:center;
  // transform: translate(-50%, -50%);
  >canvas{
    // flex-shrink:0;
    // width:50%;
    // height:50%;
    // width:100%;
    // height:200px;
    // height:100
  }
}
.text{
  position:absolute;
  top:50%;
  left:0;
  color:black;
}