@import "3_styles/_variables";
@import "3_styles/_mixins";
@import "5_components/2_Modal/UploadingModal.module.scss";

.boxContainer{
  transition:all 0.3s ease;
  position:relative;
  min-width: 100%;
  height:100%;
  padding: 3% 4% 4% 4%;
  box-sizing: border-box;
  background: $dark-layer1-background;
  @include flexTo(column, flex-start, flex-start);
  >.backButton{
    position:absolute;
    top:0;
    left:0;
    font-size: 0.8vw;
    margin:0.5vw 1vw;
    // font-weight: 600;
    user-select:none;
    cursor:pointer;
    &:hover{
      color: $dark-active-font;
    }
  }
  > .boxTitle {
    padding-top:1vw;
    // border: 1px red solid;
    font-size: 2.2vw;
    margin:auto;
    margin-bottom: 15px;
    display:flex;
    flex-direction:row;
    width:100%;
    >div{
      // @include borderBox(1px, red);
      // margin-right:8px;
      width:14vw;
      &:nth-child(2){
        width:18vw;
        // color:red;
      }
      >label{
        font-size: 1.1vw;
        // display:block;
        >input{
          all: unset;
          padding-left: 0.5vw;
          margin-top: 0.2vw;
          outline: none;
          border: none;
          background-color: #202223;
          font-size: 1vw;
          display:block;
          width:92%;
          color: white;
          height: 1.5vw;
          font-size: 0.8vw;
          -webkit-appearance:none;
          -moz-appearance: none;
          -o-appearance:none;
          appearance: none;
           
        }
      }
    }
    >.searchBtn{
      margin-top: 1vw;
      margin-left: 0.5vw;
      // border:1px solid yellow;
      border-radius: 3px;
      width:8vw;
      height: 2.5vw;
      @include flexTo(row, center, center);
      font-size: 1vw;
      background: $dark-layer2-background;
      user-select:none;
      cursor:pointer;
      &:hover{
        background: $dark-active-font;
      }
    }
  }
  > .boxContent{
    // @include borderBox(1px, red);
    // border: 1px red solid;
    // background: $dark-layer1-background;
    height: 100%;
    width:100%;
    // width:70%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  >.buttonGroup{
    width:100%;
    @include flexTo(row, center, center);
    // border:5px red solid;
    > .nextButton{
      @include nextButton();
    }
  }
}
