@import "3_styles/_variables";
@import "3_styles/_mixins";

.table {
  table-layout: fixed;
  // border-collapse: collapse;
  border-spacing: 5px;
  // border:1px red solid;
  width:100%;
  margin-top: 20px;
  // display: flex;
  // flex-direction: column;
  th,
  td {
    // border:1px red solid;
    text-align: left;
    vertical-align: top;
  }

  th {
    // width:50%;
    height: 25px;
    font-size: 17px;
    font-weight: 600;
    // padding: 0px 40px 0 0;
    // border: 1px blue solid;
    display: flex;
    // margin-top: 5px;
  }

  td {
    width:75%;
    font-size: 16px;
    font-weight: 400;
    word-break: break-all;
    // border:1px green solid;
    // margin-top: 5px;
    // padding:0px 0 0 20px;
    >span {
      // margin-top: 5px;
      // border:1px red solid;
    }
  }
}