@import "3_styles/_variables";
@import "3_styles/_mixins";

:export {
  activeColor: $dark-active-font;
}

.savedvaluelist {
  color:red;
  opacity:0.7;
  &:hover{
    color:red;
    opacity:1;
  }
}
.currentvalue {
  color:$dark-active-font;
  opacity:0.7;
  &:hover{
    color:$dark-active-font;
    opacity:1;
  }
}
.colorbarWrapper{
  // @include borderBox(3px, blue);
  @include flexTo(row, center, center);
  >.colorbarContainer{
    // @include borderBox(3px, red);
    margin: auto 2vw;
    height: 70%;
    width:3vw;
  }
}

.imageWrapper {
  // @include borderBox(3px, blue);
  // overflow:scroll;
  // width: 90%;
  // height:100%;

  // height:100%;
  // overflow:scroll;
  >div {
    // @include borderBox(3px, red);
    @include flexTo(row, center, flex-start);

    &.divUpper {
      >div {
        // @include borderBox(1px, white);
        overflow: hidden;
        // width:30%;
        height: 100%;

        &.divCoronal {
          // border: 1px red solid;
          // @include flexTo(row, flex-end, flex-end);
        }

        &.divSagittal {
          // @include flexTo(row, flex-start, flex-end);
        }
      }
    }

    &.divLower {
      >div {
        // @include borderBox(1px, white);
        overflow: hidden;
        // width:30%;
        height: 100%;

        // height:100%;
        // @include flexTo(row,center,center);
        &.divAxial {
          // @include flexTo(row, flex-end, flex-start);
          // display
        }

        &.divMip {
          // @include flexTo(row, flex-start, flex-start);
        }
      }
    }

    // >.divCoronal, .divSagittal, .divAxial, .divMip{
    //   border:1px white solid;
    //   overflow:hidden;
    //   height:20%;
    //   width:40%;
    // }
  }
}