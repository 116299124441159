@import "3_styles/_variables";
@import "3_styles/_mixins";

:export {
  dropdivColor: $dark-default-background;
}
.container {
  // @include borderBox(5px, red);
  @include flexTo(row, flex-start, flex-start);
  align-self: flex-start;
  flex-flow: row wrap;
  width: 100%;
  // height: 100%;
  // overflow: auto;

  .brain_atlas {
    // @include borderBox(2px, yellow);
    width: 45%;
    font-weight: 700;
    font-size: 1vw;
    // margin-right: 5%;
    // border: 1px solid red;
    > div {
      margin: 1vw auto;
    }
  }
  .reference_region {
    // @include borderBox(2px, yellow);
    width: 50%;
    > div {
      font-weight: 700;
      font-size: 1vw;
    }
  }

  .referece_tracer {
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    // font-weight: 400;
    // font-size: 0.8vw;
    > div {
      // border: 1px solid red;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5vw;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 0.8vw;
        // border: 1px solid red;
        padding-left: 0.5vw;
        height: 1.3vw;
        margin-left: 0.2vw;
      }
    }
  }

  .brain_regions_analyze {
    // @include borderBox(2px, yellow);
    font-weight: 700;
    font-size: 1vw;
    width: 100%;
    // height: 80vw;
  }
}

.brain_regions_analyze_subContent {
  // border: 5px solid yellow;
  box-sizing: border-box;
  width: 100%;
  padding: 2vw 0 0 2vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;
    gap: 1vw;
    > div:nth-child(2) {
      margin-left: 1vw;
      // border: 3px solid red;
      flex-wrap: wrap;
      display: flex;
      gap: 0.5vw;
      // height:300px;
      max-height:500px;
      transition:all 0.2s ease;
      overflow:scroll;
      @include scrollInvisible();
    }
  }
}
.brain_regions_analyze_subContent_dat {
  // border: 5px solid yellow;
  box-sizing: border-box;
  width: 100%;
  padding: 2vw 0 0 2vw;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  > div {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;
    gap: 1vw;
    > div:nth-child(3) {
      margin-left: 1vw;
      // border: 3px solid red;
      flex-wrap: wrap;
      display: flex;
      gap: 0.5vw;
      // height:300px;
      max-height:500px;
      transition:all 0.2s ease;
      overflow:scroll;
      @include scrollInvisible();
    }
  }
}

.brain_regions_analyze_subContent_subregion_overflow {
  // border: 1px solid red;
  cursor: default;
  width: 9.5vw;
  height: 2vw;
  background-color: $dark-default-background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 0.5vw;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8vw;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  > div:nth-child(1) {
    // border: 1px solid yellow;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
    &:hover {
      white-space: nowrap;
      -webkit-animation-name: marquee;
      -webkit-animation-timing-function: linear;
      -webkit-animation-duration: 3s;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-name: marquee;
      -moz-animation-timing-function: linear;
      -moz-animation-duration: 3s;
      -moz-animation-iteration-count: infinite;
      -ms-animation-name: marquee;
      -ms-animation-timing-function: linear;
      -ms-animation-duration: 3s;
      -ms-animation-iteration-count: infinite;
      -o-animation-name: marquee;
      -o-animation-timing-function: linear;
      -o-animation-duration: 3s;
      -o-animation-iteration-count: infinite;
      animation-name: marquee;
      animation-timing-function: linear;
      animation-duration: 3s;
      animation-iteration-count: infinite;
    }
  }
  > div:nth-child(2) {
    // border: 1px solid cyan;
    position: absolute;
    right: 2%;
    height: 100%;
    width: 1vw;
    outline: 5px solid $dark-default-background;
    display: flex;
    align-items: center;
    background-color: $dark-default-background;
    > img {
      width: 1vw;
      height: 1vw;
    }
  }
}

.brain_regions_analyze_subContent_subregion {
  // border: 1px solid red;
  cursor: default;
  width: 9.5vw;
  height: 2vw;
  background-color: $dark-default-background;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 0.5vw;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8vw;
  border-radius: 3px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  > div:nth-child(1) {
    // border: 1px solid yellow;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
  }
  > div:nth-child(2) {
    // border: 1px solid red;
    height: 100%;
    position: absolute;
    right: 2%;
    display: flex;
    align-items: center;
    background-color: $dark-default-background;
    width: 1vw;
    outline: 5px solid $dark-default-background;

    > img {
      width: 1vw;
      height: 1vw;
    }
  }
}

.brain_regions_analyze_subContent_title {
  display: flex;
  gap: 0.5vw;
  // border: 1px solid yellow;
  > div:nth-child(1) {
    > img {
      width: 1vw;
      height: 1vw;
    }
  }
  > div:nth-child(2) {
    font-weight: 500;
    font-size: 1vw;
  }
}




@-webkit-keyframes marquee {
  from {
    -webkit-transform: translate(0%);
  }
  99%,
  to {
    -webkit-transform: translate(-100%);
  }
}
@-moz-keyframes marquee {
  from {
    -moz-transform: translate(0%);
  }
  99%,
  to {
    -moz-transform: translate(-100%);
  }
}
@-ms-keyframes marquee {
  from {
    -ms-transform: translate(0%);
  }
  99%,
  to {
    -ms-transform: translate(-100%);
  }
}
@-o-keyframes marquee {
  from {
    -o-transform: translate(0%);
  }
  99%,
  to {
    -o-transform: translate(-100%);
  }
}
@keyframes marquee {
  from {
    transform: translate(0%);
  }
  99%,
  to {
    transform: translate(-100%);
  }
}

.ROIButton{
  // border:1px solid red;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap:0.5vw;
  width: 18%;
  cursor: pointer;
  &:hover{
    >span{
      color:$dark-active-font;
    }
  }
 >span{
   color:white;
 }
}

.ROIButton_DAT{
  // border:1px solid red;
  opacity: 30%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap:0.5vw;
  width: 18%;
  cursor: pointer;
 >span{
   color:white;
 }
}

.brain_regions_analyze_subContent_atlasTitle{
  font-weight: 500;
  font-size: 1vw;
  // border:1px solid red;
  margin-bottom: 1vw;
}

.brain_regions_analyze_subContent_title_dat{
  display: flex;
  gap: 0.5vw;
  // border: 1px solid yellow;
  margin-left: 1%;
  > div:nth-child(1) {
    > img {
      width: 1vw;
      height: 1vw;
    }
  }
  > div:nth-child(2) {
    font-weight: 350;
    font-size: 1vw;
  }
}

.container_dat {
  // @include borderBox(5px, red);
  // @include flexTo(row, flex-start, flex-start);
  align-self: flex-start;
  flex-flow: row wrap;
  width: 100%;
  // height: 100%;
  // overflow: auto;
  margin-bottom: 3vw;
  .brain_atlas {
    // @include borderBox(2px, yellow);
    width: 45%;
    font-weight: 700;
    font-size: 1vw;
    // margin-right: 5%;
    // border: 1px solid red;
    > div {
      margin: 1vw auto;
    }
  }
  .reference_region_dat {
    // @include borderBox(2px, yellow);
    margin-bottom: 3vw;
    width: 50%;
    > div {
      font-weight: 700;
      font-size: 1vw;
    }
  }

  .referece_tracer_dat {
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    // font-weight: 400;
    // font-size: 0.8vw;
    > div {
      // border: 1px solid red;
      display: flex;
      // justify-content: space-between;
      gap: 10%;
      align-items: center;
      margin-bottom: 0.5vw;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 0.8vw;
        // border: 1px solid red;
        padding-left: 0.5vw;
        height: 1.3vw;
        margin-left: 0.2vw;
      }
    }
  }

  .brain_regions_analyze {
    // @include borderBox(2px, yellow);
    font-weight: 700;
    font-size: 1vw;
    width: 100%;
    // height: 80vw;
  }
}

.atlas_dat_continer{
  // border:1px solid yellow;
  display: flex;
  margin-top: 1vw;
  gap: 20%;
  >div{
    font-weight: 700;
    font-size: 1vw;
    >div{
      margin-top: 1vw;
      // border:1px solid yellow;
      width: 14vw;
      height: 2vw;
      position: relative;
      display: flex;
      background: #292d30;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

// .reference_region_dat{
//   width: 50%;
//   > div {
//     font-weight: 700;
//     font-size: 1vw;
//   }
// }