@import "3_styles/_variables";
@import "3_styles/_mixins";
.settingStyle{
  // border: 1px solid red;
  .container {
    // border: 1px solid white;
    width: 14vw;
    height: 2vw;
    position: relative;
    display: flex;
    user-select: none;
  }
  
  .title_container {
    // border: 1px solid red;
    position: relative;
    width: 100%;
    // height: 2vw;
    height: 100%;
    cursor: pointer;
    // padding: 0.2vw 0.2vw 0.2vw 0.2vw;
    background: $dark-default-background;
    border-radius: 3px;
    @include flexTo(row, center, center);
  
    >div:nth-child(1) {
      // border: 1px solid yellow;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85%;
      font-size: 0.8vw;
      font-weight: 400;
    }
  }
  
  .arrow_img {
    >img {
      width: 0.8vw;
      height: 0.5vw;
    }
  }
  
  .drop_container {
    position: absolute;
    top: 105%;
    width: 100%;
    z-index: 1;
  
    >div {
      cursor: pointer;
      // border: 1px solid yellow;
      height: 2vw;
      // background-color: $dark-default-background;
      font-size: 0.8vw;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
  
      &:hover {
        background-color: $dark-layer2-background;
      }
    }
  }
}

.container {
  // border: 1px solid white;
  width: 14vw;
  height: 2vw;
  position: relative;
  display: flex;
  user-select: none;
}

.title_container {
  // border: 1px solid red;
  position: relative;
  width: 100%;
  // height: 2vw;
  height: 100%;
  cursor: pointer;
  // padding: 0.2vw 0.2vw 0.2vw 0.2vw;
  background: $dark-default-background;
  border-radius: 3px;
  @include flexTo(row, center, center);

  >div:nth-child(1) {
    // border: 1px solid yellow;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85%;
    font-size: 0.8vw;
    font-weight: 400;
  }
}

.arrow_img {
  >img {
    width: 0.8vw;
    height: 0.5vw;
  }
}

.drop_container {
  position: absolute;
  top: 105%;
  width: 100%;
  z-index: 1;

  >div {
    cursor: pointer;
    // border: 1px solid yellow;
    height: 2vw;
    // background-color: $dark-default-background;
    font-size: 0.8vw;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $dark-layer2-background;
    }
  }
}

.headerStyle{
  // border:1px solid red;
  .container {
    // border: 1px solid white;
    border-radius: 3px;
    width: 6vw;
    height: 2vw;
    position: relative;
    display: flex;
    user-select: none;
  }
  
  .title_container {
    // border: 1px solid red;
    position: relative;
    width: 100%;
    // height: 2vw;
    height: 100%;
    cursor: pointer;
    // padding: 0.2vw 0.2vw 0.2vw 0.2vw;
    background: $dark-default-background;
    border-radius: 3px;
    @include flexTo(row, center, center);
  
    >div:nth-child(1) {
      // border: 1px solid yellow;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85%;
      font-size: 0.8vw;
      font-weight: 400;
    }
  }
  
  .arrow_img {
    >img {
      width: 0.8vw;
      height: 0.5vw;
    }
  }
  
  .drop_container {
    position: absolute;
    top: 105%;
    width: 100%;
    z-index: 1;
  
    >div {
      cursor: pointer;
      // border: 1px solid yellow;
      height: 2vw;
      // background-color: $dark-default-background;
      font-size: 0.8vw;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
  
      &:hover {
        background-color: $dark-layer2-background;
      }
    }
  }
}