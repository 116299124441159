@import "3_styles/_variables";
@import "3_styles/_mixins";

%btn__common {
  height: 3vw;
  font-size: 1vw;
  margin-top: 2vw;
  > button {
    @include button-style-none();
    width: 100%;
    height: 100%;
    background-color: $dark-active-font;
  }
}

.pass-btn {
  @extend %btn__common;
}

.logout-btn {
  @extend %btn__common;
  > button {
    background-color: $dark-layer1-background;
  }
}

.signup-btn,
.forgot-btn {
  @extend %btn__common;
  height: 2vw;
  > button {
    font-size: 0.7vw;
    line-height: 2vw;
    height: 2vw;
  }
}
