@import "3_styles/_variables";
@import "3_styles/_mixins";

.coronal{
  // border:10px white solid;
  width: 100%;
  height: 100%;
  // >canvas{
  //   width:100%;
  //   height:100%;
  //   border:10px red solid;
  // }
}

.sagittal{
  width:102%;
  height:102%;
}

.axial{
  width: 100%;
  height: 100%;
}

.mip{
  width: 140%;
  height:100%;
}