@import "3_styles/_variables";
@import "3_styles/_mixins";

// Quanttable
$quant-table-fontSize: 0.9vw;

.table {
  // border: 4px blue solid;
  user-select: none;
  width: 100%;
  min-height: 100%;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;

  th,
  td {
    white-space: nowrap;
    // font-size:$quant-table-fontSize;
    &.Region {
      width: 30%;
      overflow-x: auto;
    }

    &.Measure {
      width: 40%;
      overflow-x: auto;
    }

    &.Total {}

    &.Left {}

    &.Right {}

    &.Centiloid {
      width: 20%;
    }
  }

  .thead {
    position: sticky;
    // z-index: 0;
    top: 0;
    background-color: $dark-default-background;
    text-align: center;
    height: 2vw;
    font-weight: normal;
    // color:red;

    >tr {
      // border: 4px yellow solid;
      >th {
        // display: flex;
        // align-items: center;
        // text-align: center;
        // border: 1px solid yellow;
        // font-weight: 400;
        margin: auto;
        text-align: center;
        // vertical-align: middle;
        cursor: pointer;

        // display: flex;
        >div {
          display: flex;
          align-items: center;
          justify-content: center;

          >span {
            height: 100%;
            font-size: $quant-table-fontSize;
          }

          >div {
            // border: 1px solid red;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.4vw;

            >img {
              // margin-top: 0.5vw;
              // border: 1px solid yellow;
              height: 0.8vw;
              width: 0.8vw;
            }
          }
        }
      }

      >td {
        
      }
    }
  }

  .tbody {
    // border: 1px solid red;
    // font-weight: 300;
    font-size: $quant-table-fontSize;

    >.trMain {
      // background: blue;
      box-sizing: border-box;
      user-select: none;
      cursor: pointer;
      // font-size: calc(0.8vw);
      font-size: $quant-table-fontSize;
      // font-weight:300;
      text-align: start;
      height: 1.5vw;

      &:hover {
        color: $dark-active-font;
      }

      >td {
        padding-left: 0.5vw;
      }

      >td:not(:nth-child(1)) {
        text-align: center;
      }
    }

    >.trSub {
      text-align: left;
      color: #91919c;
      transition: all 0.1s ease;
      &.active{
        height: 1.5vw;
        opacity:1;
        visibility: visible;
      }
      &.inactive{
        height: 0;
        line-height:0;
        opacity:0;
        visibility: collapse;
      }
      font-size: $quant-table-fontSize;

      >td:not(:nth-child(1)) {
        text-align: center;
      }

      >td {
        padding-left: 0.5vw;
      }
    }
  }
}

.hoverRegion{
  border:1px solid yellow;
  position:absolute;
  // top:100%;
}