@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  // @include borderBox(4px, yellow);
  width: 100%;
  margin: auto;
  @include flexTo(column, flex-start, center);
  align-self: flex-start;
  gap: 1vw;
  // overflow-y:auto;
  // overflow-x:hidden;
  // @include flexTo(column, flex-start, flex-start);
  // flex-flow: row wrap;
}

.title {
  // border: 1px solid red;
  width: 100%;
  display: flex;
  gap: 0.5vw;
  > div:nth-child(1) {
    > img {
      cursor: pointer;
      width: 1vw;
      height: 1vw;
    }
  }
  > div:nth-child(2) {
    font-weight: 500;
    font-size: 1vw;
  }
}

.checkList {
  display: flex;
  flex-direction: column;
  // border: 1px solid cyan;
  justify-content: center;
  gap: 0.5vw;
  width: 75%;
  > div {
    height: 2vw;
    padding: 0 0.5vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $dark-default-background;
    border-radius: 3px;
    cursor: pointer;
    > div:nth-child(2) {
      > img {
        width: 1vw;
        height: 1vw;
      }
    }
  }
}
