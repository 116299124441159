@import "3_styles/_variables";
@import "3_styles/_mixins";

// .container {
//   @include flexTo(column, center, center);
//   @include borderBox(1px, red);
//   > div {
//     @include borderBox(1px, red);
//   }
//   background: $dark-layer1-background;
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }
.container {
  background: $dark-default-background;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include flexTo(column, center, center);
  // @include borderBox(1px, red);
  > div:nth-child(1) {
    width: 45%;
    height: 100%;
    // @include borderBox(1px, red);
    @include flexTo(column, center, left);
  }
}
.title {
  font-size: 1.2vw;
  font-weight: 500;
}

.pass-btn {
  // border: 1px solid cyan;
  height: 3vw;
  font-size: 1vw;
  margin-top: 1vw;

  > button {
    @include button-style-none();
    // border: 1px solid cyan;
    width: 100%;
    height: 100%;
    background-color: $dark-active-font;
  }
}

.policy {
  @include flexTo(row, center, center);
  // border: 1px solid yellow;
  font-size: 0.7vw;
  gap: 0.5vw;
  padding: 1vw 0 1vw 0;
  > input {
    width: 0.7vw;
    height: 0.7vw;
  }
}

.CheckPassword {
  color: red;
  font-size: 0.5vw;
}
