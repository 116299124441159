@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  @include cardContainer();
  
  .graphContainer{
    // border:4px red solid;
    box-sizing:border-box;
    width: 100%;
    height: 80%;
    position: relative;

    .textContainer{
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      display:flex;
      flex-direction:column;
      align-items:center;
      > span{
        &:nth-child(1){
          font-size: 5vmin;
        }
        &:nth-child(2){
          font-size: 3vmin;
        }
      }
    }
  }

  .legendContainer{
    // border: 1px red solid;
    display: flex;
    justify-content: center;
    > div{
      padding-left:0.5vw;
      margin-left:1vw;
    }
  }
}