@import "3_styles/_variables";
@import "3_styles/_mixins";

.container {
  @include cardContainer();

  .graphContainer{
    // border:4px red solid;
    box-sizing:border-box;
    width: 100%;
    height: 80%;
  }
  
  .legendContainer{
    
    // border: 1px red solid;
    display: flex;
    justify-content: center;
    > div{
      padding-left:0.5vw;
      margin-left:1vw;
    }
  }
}
