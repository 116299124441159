@import "3_styles/_variables";
@import "3_styles/_mixins";

.A4pages {
  // @include borderBox(5px, red);
  width: 210mm;
  height: 297mm;

  padding: 20mm;
  margin: 10mm auto;
  // border: 1px #D3D3D3 solid;
  // border: 4px cyan solid;
  background: white;
  color: black;
  // position: relative;
}

// .A4pagesSVG{
//   // @include borderBox(5px, red);
//   width: 210mm;
//   min-height: 297mm;
//   padding: 20mm;
//   margin: 10mm auto;
//   // border: 1px #D3D3D3 solid;
//   border: 4px red solid;
//   background: white;
//   color:black;
// }

// svg{
//   @include borderBox(1px, red);
//   width:100%;
//   height:100%;
//   >text{
//     font-size:3rem;
//     font-weight:700;
//     text-anchor:start;
//     dominant-baseline: hanging;
//   }
// }
