@import "3_styles/_variables";
@import "3_styles/_mixins";

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* outline: 15px yellow solid; */
  /* box-sizing:border-box; */

  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: $dark-default-background;

}

code {
  font-family: "Montserrat", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}
