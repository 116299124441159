@import "3_styles/_variables";
@import "3_styles/_mixins";

.leftBtn {
  // border: 1px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  height: 2vw;
  width: 8vw;
  cursor: pointer;
  border-radius: 3px;
  background-color: $dark-layer1-background;
  &:hover {
    outline: 1px solid $dark-active-font;
  }
  > div:nth-child(1) {
    // border: 1px solid red;
    display: flex;
    > img {
      height: 1.5vw;
      width: 1.5vw;
    }
  }
}

.deleteBtn {
  // border: 1px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  height: 2vw;
  width: 8vw;
  cursor: pointer;
  border-radius: 3px;
  background-color: $dark-layer1-background;
  &:hover {
    outline: 1px solid $dark-active-negative-font;
  }
  > div:nth-child(1) {
    // border: 1px solid red;
    display: flex;
    > img {
      height: 1.5vw;
      width: 1.5vw;
    }
  }
}

.rightBtn {
  // border: 1px solid yellow;
  @include flexTo(row, center, center);
  gap: 0.5vw;
  height: 2vw;
  width: 8vw;
  cursor: pointer;
  border-radius: 3px;
  background-color: $dark-layer1-background;
  &:hover {
    outline: 1px solid $dark-active-font;
  }
  > div {
    // border: 1px solid red;
    @include flexTo(row, center, center);
    > img, svg {
      height: 1.5vw;
      width: 1.5vw;
    }
  }
  > label{
    // border:1px red solid;
    width:100%;
    height:100%;
    @include flexTo(row, center, center);
    // box-sizing:border-box;
  }
}

.worklistBtn {
  // border: 1px solid yellow;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  height: 2vw;
  width: 8vw;
  cursor: pointer;
  border-radius: 3px;
  background-color: $dark-layer2-background;
  &:hover {
    outline: 1px solid $dark-active-font;
  }
  > div:nth-child(1) {
    // border: 1px solid red;
    display: flex;
    > img {
      height: 1.5vw;
      width: 1.5vw;
    }
  }
}
